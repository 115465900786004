import React from "react"
import { Link } from 'gatsby'
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from '../components/Layout'
import CTAStart from "../components/CTAStart"
import SEO from "../components/SEO"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

const Method = (props) => {
    return (
        <Layout>
            <SEO title={"Method"} />
            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h1 className="title-xl mb-8">
                            Method
                        </h1>
                    </div>
                </div>
                <div className="rbs-bg bg-07-a"></div>
            </div>

            <div className="rbs-section">
                <div className="rbs-bg bg-07-b"></div>
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-16">Taylored for Startups</h2>
                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Perfect Fit</h3>
                                <p className="body-l text-rbs-black-50">Remote Brand Sprint is something startup founders have been asking us over the years — a process designed to quickly launch quality brands. Get a functional toolbox and start building the brand driving your success.</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">For Founders</h3>
                                <p className="body-l text-rbs-black-50">Most members of our team have founded startups or helped design digital products themselves. We know how hard it can be to start something. Now we also know how we can help you with our experience.</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Get Going</h3>
                                <p className="body-l text-rbs-black-50">When starting out, you don't need to over do it with fancy looking brand manuals. Get the brand toolbox you actually need and get growing your business quick.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-16">Just Right</h2>
                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Fit</h3>
                                <p className="body-l text-rbs-black-50">Remote Brand Sprint is something startup founders have been asking us over the years — a process designed to quickly launch quality brands. Get a functional toolbox and start building the brand driving your success.</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Branding is Gardening</h3>
                                <p className="body-l text-rbs-black-50">Building brands take time. Brands are not launched. They grow over time. Plant your first seeds &mdash; just create a solid foundation and get started.</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">80/20 Principle</h3>
                                <p className="body-l text-rbs-black-50">By focusing on only the most important aspects, and skipping all which can be added later, we are able to offer you a revolutionary branding service insanely fast, with just a fraction of the budget. Without compromising on the quality.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rbs-bg bg-07-a"></div>
            </div>

            <div className="rbs-section">
                <div className="rbs-bg bg-07-b"></div>
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-16">Instant Gains</h2>
                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Till You Make It</h3>
                                <p className="body-l text-rbs-black-50">Having  a convincing visual identity and solid brand principles is one of the most effective ways to show progress. Whether it’s gaining the trust of your customers or landing the next round of investment, it helps to materialise your future vision in the eyes of others. Effective branding makes you seem bigger, better, smarter than you currently are.</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Speed Matters</h3>
                                <p className="body-l text-rbs-black-50">As a startup you need to move fast. That’s why we created the Remote Design Sprint. Focused engagements often times yield superior results to months of work. A small group of experts deployed on a narrow problem can yield amazing results.</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Value/Risk</h3>
                                <p className="body-l text-rbs-black-50">When buying creative services by the billable hour, you never know the total cost. It’s impossible to assess a ROI of such a deal. What you know is that you pay for all the perks, flat whites, lunch-karaokes and generous travel allowances. You know your financial interests aren’t aligned with the agency. We’ve seen such projects and budgets grow in magnitudes. With RBS — what you see is what you get.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="case-bg-03 p-16 pt-32 md:p-32">
                <div className="text-white case-grid">
                    <div>
                        <h3 className="title">Blue Key</h3>
                        <p className="body-xl opacity-50">Privacy-first 2FA app</p>
                    </div>
                    <div className="col-span-2">
                        <p className="body-l opacity-50">We helped Blue Key 2FA authentication app to launch their first brand identity in order to attract initial financing and gain the attention of the open-source cryptography community. After the remote brand sprint, Blue Key partnered up with our sister company <a href="https://taru.io" target="_blank" className="link" rel="noreferrer">TARU</a> to prtotype designs for their iOS app and the marketing landing page.</p>
                    </div>
                </div>
                <div className="relative w-full mx-auto">
                    <Img imgStyle={{ objectFit: 'contain' }} fluid={props.data.case03.childImageSharp.fluid} />
                </div>

                <div className="text-center mt-16">
                    <Link to="/cases" className="text-white opacity-50 body-l link-arrow">View more cases</Link>
                </div>

            </div>

            <CTAStart />
        </Layout>
    )
}


export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    case03: file(relativePath: { eq: "case-03.png" }) {
      ...fluidImage
    }
  }
`

export default Method;